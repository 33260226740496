import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              © {new Date().getFullYear()} <a href="https://www.aurumtrans.com/" target="_blank" rel="noopener noreferrer">Aurum Trans Solutions</a> <span className="d-none d-sm-inline-block"> - Technology Partner <a href="https://d10x.io/" target="_blank" rel="noopener noreferrer">D10X</a>.</span>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
