import { 
    getPoDUploadedButBillPending, 
    getPoDUploadedButMRPending,
    getfreightBillsHierarchical,
    gettotalUnbilledHierarchical,
    getBillsPendingApproval,
    getDeliveredLrsUnbilledReport,
    getpodUploadedReceivedBillPending,
    getpodUploadedMrPending,
    getfreightBills,
    gettotalUnbilledreport,
    getbillsPendingApproval,
    getdeliveredLrsUnbilledReport
} from "api";

    export const getPoDUploadedButBillPendingReq = async (body) => {
        const response = await getPoDUploadedButBillPending(body);
        return response;
    };
    export const getPoDUploadedButMRPendingReq = async (body) => {
        const response = await getPoDUploadedButMRPending(body);
        return response;
    };
    export const getfreightBillsHierarchicalReq = async (body) => {
        const response = await getfreightBillsHierarchical(body);
        return response;
    };
    export const gettotalUnbilledHierarchicalReq = async (body) => {
        const response = await gettotalUnbilledHierarchical(body);
        return response;
    };
    export const getBillsPendingApprovalReq = async (body) => {
        const response = await getBillsPendingApproval(body);
        return response;
    };
    export const getDeliveredLrsUnbilledReportReq = async (body) => {
        const response = await getDeliveredLrsUnbilledReport(body);
        return response;
    };

    export const getpodUploadedReceivedBillPendingReq = async (body) => {
        const response = await getpodUploadedReceivedBillPending(body);
        return response;
    };
    export const getpodUploadedMrPendingReq = async (body) => {
        const response = await getpodUploadedMrPending(body);
        return response;
    };
    export const getfreightBillsReq = async (body) => {
        const response = await getfreightBills(body);
        return response;
    };
    export const gettotalUnbilledreportReq = async (body) => {
        const response = await gettotalUnbilledreport   (body);
        return response;
    };
    export const getbillsPendingApprovalReq = async (body) => {
        const response = await getbillsPendingApproval(body);
        return response;
    };
    export const getdeliveredLrsUnbilledReportReq = async (body) => {
        const response = await getdeliveredLrsUnbilledReport(body);
        return response;
    };

