import axios from "axios";

const baseUrl = process.env.API_BASE_URL || "https://aurum.d10xpoc.xyz/api";

const API_URL = {
  login: "/iam/login/",
  logout: "/iam/logout/",
  freightBillsHierarchical: "/billing/freight-bills-aggregate/",
  totalUnbilledHierarchical: "/billing/total-unbilled-hierarchical/",
  PoDUploadedButMRPending: "/billing/pod-uploaded-mr-pending-hierarchical/",
  PoDUploadedButBillPending: "/billing/pod-uploaded-received-bill-pending-hierarchical/",
  BillsPendingApproval: "/billing/bills-pending-approval-hierarchical/",
  DeliveredLrsUnbilledReport: "/billing/delivered-lrs-unbilled-hierarchical/",
  podUploadedMrPending : "/billing/pod-uploaded-mr-pending/",
  podUploadedReceivedBillPending : "/billing/pod-uploaded-received-bill-pending/",
  freightBills: "/billing/freight-bills/",
  totalUnbilledreport : "/billing/total-unbilled-report/",
  deliveredLrsUnbilledReport: "/billing/delivered-lrs-unbilled-report/",
  billsPendingApproval: "/billing/bills-pending-approval/"
};

export async function login(body) {
  return new Promise((resolve) => {
    axios
      .post(`${baseUrl}${API_URL.login}`, body)
      .then((res) => {
        resolve(res.data);
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        resolve(error.response.data);
      });
  });
}

export async function logout(body) {
    return new Promise((resolve) => {
      axios
        .post(`${baseUrl}${API_URL.logout}`, body,{ 
            withCredentials : true })
        .then((res) => {
          resolve(res.data);
          return res.data;
        })
        .catch((error) => {
          console.log(error);
          resolve(error.response.data);
        });
    });
  }
  
export async function getfreightBillsHierarchical(queryParams) {
return new Promise((resolve, reject) => {
    axios
    .get(`${baseUrl}${API_URL.freightBillsHierarchical}`, { 
        params: queryParams , withCredentials : true })
    .then((res) => {
        resolve(res.data);
    })
    .catch((error) => {
        reject(error.response.status);
    });
});
}

export async function gettotalUnbilledHierarchical(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.totalUnbilledHierarchical}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getPoDUploadedButMRPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.PoDUploadedButMRPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getPoDUploadedButBillPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.PoDUploadedButBillPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getBillsPendingApproval(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.billsPendingApproval}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getDeliveredLrsUnbilledReport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.DeliveredLrsUnbilledReport}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getpodUploadedMrPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.podUploadedMrPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getpodUploadedReceivedBillPending(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.podUploadedReceivedBillPending}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getfreightBills(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.freightBills}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function gettotalUnbilledreport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.totalUnbilledreport}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getdeliveredLrsUnbilledReport(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.deliveredLrsUnbilledReport}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}

export async function getbillsPendingApproval(queryParams) {
    return new Promise((resolve, reject) => {
        axios
        .get(`${baseUrl}${API_URL.billsPendingApproval}`, { 
            params: queryParams , withCredentials : true })
        .then((res) => {
            resolve(res.data);
        })
        .catch((error) => {
            reject(error.response.status);
        });
    });
}