import React , {useEffect} from "react"
import { connect } from "react-redux";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import { options } from "utils/pdfOptions";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card,ButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem,CardTitle } from "reactstrap";
import generatePDF from "react-to-pdf";
import { useState } from "react";
import { getfreightBillsHierarchicalReq, getfreightBillsReq } from "./billingService";
import { getdeliveredLrsUnbilledReportReq, getDeliveredLrsUnbilledReportReq } from "./billingService";
import { getPoDUploadedButBillPendingReq, getpodUploadedReceivedBillPendingReq } from "./billingService";
import { getPoDUploadedButMRPendingReq, getpodUploadedMrPendingReq } from "./billingService";
import { gettotalUnbilledHierarchicalReq, gettotalUnbilledreportReq } from "./billingService";
import { useNavigate } from 'react-router-dom';


const Billing = (props) => {

  document.title = "Aurum | Billing";
  const [drp_link, setdrp_link] = useState(false);
  const navigate = useNavigate();

  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Billing", link: "/billing" },
  ]

  const handleCardTitleClick = (destinationPage) => (event) => {
    event.stopPropagation();
    navigate(destinationPage); // Replace with the desired route
  };

  useEffect(() => {
    props.setBreadcrumbItems('Billing' , breadcrumbItems)
  },)

  const getTargetElement = () => document.getElementById("pdf-container");

  const downloadPDF = () => generatePDF(getTargetElement, options);

  return (
    <React.Fragment >
        <div style={{ position: "relative" }}>
            <div
            style={{
                position: "absolute",
                top: -50,
                right: 10,
                display: "flex",
            }}
            >
            <button
                type="submit"
                className="btn btn-secondary w-m mx-2"
                onClick={downloadPDF}
            >
            <i className="fas fa-cloud-download-alt me-2"></i> Download PDF
            </button>
                <ButtonDropdown
                    isOpen={drp_link}
                    toggle={() => {
                        setdrp_link(!drp_link)
                    }}
                >
                    <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                    <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>Email</DropdownItem>
                        <DropdownItem>Whatsapp</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        </div>
        <div style={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      gap: '12px' 
    }}>
      <div>
        <Card body style={{ flex: '1 1 '}} className="overflow-hidden">
        <CardTitle onClick={handleCardTitleClick("/bills-pending-approval")} style={{ cursor:"pointer"}} className="h4">Bills Pending Approval</CardTitle>
          <DrillDownTable 
            fetchDataFn={getfreightBillsHierarchicalReq} 
            fetchDataAtBreadcrumbLimit={getfreightBillsReq}  
            destinationPage={"/bills-pending-approval"}
          />
        </Card>
      </div>
      <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
        <Card body className="overflow-hidden">
        <CardTitle onClick={handleCardTitleClick("/delivered-lrs-unbilled-report")} style={{ cursor:"pointer"}} className="h4">Delivered LRs Unbilled Report</CardTitle>
          <DrillDownTable 
            fetchDataFn={getDeliveredLrsUnbilledReportReq} 
            fetchDataAtBreadcrumbLimit={getdeliveredLrsUnbilledReportReq} 
            destinationPage={"/delivered-lrs-unbilled-report"}
          />
        </Card>
      </div>
      <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
        <Card  body className="overflow-hidden">
        <CardTitle onClick={handleCardTitleClick("/pod-uploaded-but-bill-pending")} style={{ cursor:"pointer"}} className="h4">PoD Uploaded But Bill Pending</CardTitle>
          <DrillDownTable 
            fetchDataFn={getPoDUploadedButBillPendingReq} 
            fetchDataAtBreadcrumbLimit={getpodUploadedReceivedBillPendingReq} 
            destinationPage={"/pod-uploaded-but-bill-pending"}
          />
        </Card>
      </div>
      <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
        <Card body className="overflow-hidden">
        <CardTitle onClick={handleCardTitleClick("/pod-upload-but-mr-pending")} style={{ cursor:"pointer"}} className="h4">PoD Uploaded But MR Pending</CardTitle>
          <DrillDownTable 
            fetchDataFn={getPoDUploadedButMRPendingReq} 
            fetchDataAtBreadcrumbLimit={getpodUploadedMrPendingReq} 
            destinationPage={"/pod-upload-but-mr-pending"}
          />
        </Card>
      </div>
      <div style={{ flex: '1 1 calc(50% - 12px)', boxSizing: 'border-box' }}>
        <Card body className="overflow-hidden">
        <CardTitle onClick={handleCardTitleClick("/total-unbilled-report")} style={{ cursor:"pointer"}} className="h4">Total Unbilled Report</CardTitle>
          <DrillDownTable 
            fetchDataFn={gettotalUnbilledHierarchicalReq} 
            fetchDataAtBreadcrumbLimit={gettotalUnbilledreportReq}
            destinationPage={"/total-unbilled-report"}
          />
        </Card>
      </div>
    </div>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Billing);