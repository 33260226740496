import { login, logout } from "api";

export const loginReq = async (body) => {
    const response = await login(body);
    return response;
};

export const logoutReq = async (body) => {
    const response = await logout(body);
    return response;
};
