import React , {useEffect} from "react"
import { connect } from "react-redux";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import { options } from "utils/pdfOptions";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { Card,ButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem } from "reactstrap";
import generatePDF from "react-to-pdf";
import { useState } from "react";

const BillingTimeAnalysis = (props) => {

  document.title = "Aurum | Dashboard";
  const [drp_link, setdrp_link] = useState(false);


  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Billing", link: "/billing" },
    { title: "Billing Time Analysis", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Billing Time Analysis' , breadcrumbItems)
  },)

  const getTargetElement = () => document.getElementById("pdf-container");

  const downloadPDF = () => generatePDF(getTargetElement, options);

  return (
    <React.Fragment>
        <div style={{ position: "relative" }}>
            <div
            style={{
                position: "absolute",
                top: -50,
                right: 10,
                display: "flex",
            }}
            >
            <button
                type="submit"
                className="btn btn-secondary w-m mx-2"
                onClick={downloadPDF}
            >
            <i className="fas fa-cloud-download-alt me-2"></i> Download PDF
            </button>
            {/* <button type="submit" className="btn btn-primary w-m mx-2 ">
            <i className="fas fa-share me-2"></i> Send Via
            </button> */}
                <ButtonDropdown
                    isOpen={drp_link}
                    toggle={() => {
                        setdrp_link(!drp_link)
                    }}
                >
                    <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                    <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>Email</DropdownItem>
                        <DropdownItem>Whatsapp</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        </div>
        <Card id="pdf-container" className="overflow-hidden">
        </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(BillingTimeAnalysis);