import React, { useRef, useEffect,useCallback,useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { capitalizeWords

 } from 'utils';
const EnhancedTable = ({ data, columns }) => {
  const gridRef = useRef(null); // Reference for the grid
  const paginationPageSizeSelector = [10, 25, 50];
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  // Define column definitions for ag-grid with initial sizes
  const columnDefs = columns.map(col => ({
    headerName: capitalizeWords(col.replace(/_/g, ' ')),
    field: col,
    sortable: true,
    filter: true,
    resizable: true,
    cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } // Prevent wrapping
  }));

  // Function to auto-size columns based on content
  const autoSizeAllColumns = () => {
    const allColumnIds = [];
    const columnApi = gridRef.current?.columnApi;
    if (columnApi) {
      columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      columnApi.autoSizeColumns(allColumnIds, false); // Auto-size columns to fit contents
    }
  };
  const onPaginationChanged = useCallback((event) => {
    // Workaround for bug in events order
    let pageSize = gridRef.current.api.paginationGetPageSize();
    setPaginationPageSize(pageSize);
  }, []);

  // Grid ready callback
  const onGridReady = (params) => {
    gridRef.current = params; // Store grid API reference
    autoSizeAllColumns(); // Auto-size columns when grid is ready
  };

  // Re-run the auto-sizing whenever the data changes
  useEffect(() => {
    if (gridRef.current) {
      autoSizeAllColumns();
    }
  }, [data]);

  return (
    <div style={{ padding: '8px' }}>
      <div
        className="ag-theme-quartz"
        style={{ width: '100%' }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          pagination
          onPaginationChanged={onPaginationChanged}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          defaultColDef={{
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: false, // Enables floating filter for each column
          }}
          onGridReady={onGridReady} // Set grid ready callback
          domLayout='autoHeight'
          // suppressCellSelection
        />
      </div>
    </div>
  );
};

export default EnhancedTable;
