import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutReq } from "./authService";

const Logout = () => {
  const dispatch = useDispatch(); 
  const navigate = useNavigate(); 

  useEffect(() => {
    const logout = async () => {
      const response= await logoutReq();
      try {
        // console.log(response);
        if (response.message === 'Logged out successfully') {
          // console.log("logout");
          localStorage.removeItem("authUser");          
          navigate("/login"); 
        } else {
          // console.log("else");
        }
        // Redirect to the login page after logout
      } catch (error) {
        console.error("Logout failed:", error);
      }
    };

    logout();
  }, [dispatch, navigate]);

  return null;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
