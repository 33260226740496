export const createQueryParamsFromBreadcrumbs = (breadcrumbs) => {
    if (!breadcrumbs) return {};  // Return an empty object if breadcrumbs is undefined or null
    
    return breadcrumbs.reduce((params, crumb) => {
      if (crumb.field) {
        params[crumb.field] = crumb.label;
      }
      return params;
    }, {});
  };
  

  export const calculateTotalRow = (data, columns) => {
    return data.reduce(
      (totals, row) => {
        columns.forEach(col => {
          if (typeof row[col] === 'number' && col !== columns[0]) {
            totals[col] = (totals[col] || 0) + row[col];
          }
        });
        return totals;
      },
      {}
    );
  };

  export const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  export const formatNumber = (num) => {
    if (typeof num !== 'number') return num;
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };