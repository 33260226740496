import React, { useEffect } from "react"
import { Spinner } from "reactstrap";

const Spinners = ({ setLoading }) => {

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [setLoading]);
    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <Spinner color="primary" />
            </div>
        </React.Fragment>
    )
}

export default Spinners;