import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

// i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (items[i].pathname === pathName) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
    document.body.setAttribute("data-sidebar", "dark");
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  const handleLinkClick = () => {
      var body = document.body;
      if (!body.classList.contains("vertical-collapsed")) {
        body.classList.add("vertical-collapsed");
        body.classList.add("sidebar-enable");
      } else {
        body.classList.remove("vertical-collapsed");
        body.classList.remove("sidebar-enable");
      }
  };

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled mm-show mm-active" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect"onClick={handleLinkClick}>
                <i className="mdi mdi-view-dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li className="menu-title">Reports</li>

            <li>
              <Link to="/billing" className="waves-effect" onClick={handleLinkClick}>
                <i className="mdi mdi-receipt"></i>
                <span>Billing</span>
              </Link>
              {/* <ul className="sub-menu">
                <li>
                  <Link to="/billing" onClick={handleLinkClick}><span>Billing</span></Link>
                </li>
                <li>
                  <Link to="/billing-time-analysis" onClick={handleLinkClick}><span>Billing Time Analysis</span></Link>
                </li>
                <li>
                  <Link to="/total-unbilled-report" onClick={handleLinkClick}><span>Total Unbilled Report</span></Link>
                </li>
                <li>
                  <Link to="/delivered-lrs-unbilled-report" onClick={handleLinkClick}><span>Delivered LRs Unbilled Report</span></Link>
                </li>
                <li>
                  <Link to="/pod-uploaded-but-bill-pending" onClick={handleLinkClick}><span>PoD uploaded but Bill pending</span></Link>
                </li>
                <li>
                  <Link to="/pod-upload-but-mr-pending" onClick={handleLinkClick}><span>PoD uploaded but MR pending</span></Link>
                </li>
                <li>
                  <Link to="/bills-pending-approval" onClick={handleLinkClick}><span>Bills Pending Approval</span></Link>
                </li>
              </ul> */}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent));
