import React , {useEffect,useState} from "react"
import { getPoDUploadedButBillPendingReq, getpodUploadedReceivedBillPendingReq } from "./billingService";
import { connect } from "react-redux";
import DrillDownTable from "components/CustomComponents/DrillDownTable";
import { Card } from "reactstrap";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { options } from "utils/pdfOptions";
import {ButtonDropdown,DropdownItem,DropdownMenu,DropdownToggle} from "reactstrap";
import generatePDF from "react-to-pdf";
import { useLocation } from "react-router-dom";

const PoDUploadedButBillPending = (props) => {
  const [drp_link, setdrp_link] = useState(false);
  const location = useLocation();
  const breadcrumbs = location.state?.breadcrumbsState || [{ label: 'Home', field: '' }];
  document.title = "Aurum | Dashboard";


  const breadcrumbItems = [
    { title: "Home", link: "/dashboard" },
    { title: "Billing", link: "/billing" },
    { title: "PoD Uploaded But Bill Pending", link: "#" }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('PoD Uploaded But Bill Pending' , breadcrumbItems)
  },)

  const getTargetElement = () => document.getElementById("pdf-container");

  const downloadPDF = () => generatePDF(getTargetElement, options);

  return (
    <React.Fragment>
            <div style={{ position: "relative" }}>
                <div
                style={{
                    position: "absolute",
                    top: -50,
                    right: 10,
                    display: "flex",
                }}
                >
                <button
                    type="submit"
                    className="btn btn-secondary w-m mx-2"
                    onClick={downloadPDF}
                >
                <i className="fas fa-cloud-download-alt me-2"></i> Download PDF
                </button>
                {/* <button type="submit" className="btn btn-primary w-m mx-2 ">
                <i className="fas fa-share me-2"></i> Send Via
                </button> */}
                    <ButtonDropdown
                        isOpen={drp_link}
                        toggle={() => {
                            setdrp_link(!drp_link)
                        }}
                    >
                        <DropdownToggle caret className="btn btn-primary w-m mx-2 ">
                        <i className="fas fa-share me-2"></i> Share Via <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>Email</DropdownItem>
                            <DropdownItem>Whatsapp</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </div>
            </div>
        <Card body id="pdf-container" className="overflow-hidden">
        <DrillDownTable 
          fetchDataFn={getPoDUploadedButBillPendingReq} 
          fetchDataAtBreadcrumbLimit={getpodUploadedReceivedBillPendingReq} 
          initialBreadcrumbs={breadcrumbs} 
            />
      </Card>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(PoDUploadedButBillPending);